<template>
  <FormField
    :id="selectRef?.id"
    :class="classField"
    :label="label"
    :isRequired="isRequired"
    :isLabelVisible="isLabelVisible"
    :isError="isError"
    :errorMessage="errorMessage"
    :errorFixed="errorFixed"
  >
    <div class="relative">
      <select
        ref="selectRef"
        v-uid
        v-bind="$attrs"
        :value="modelValue"
        :disabled="isDisabled"
        class="md:hidden"
        :class="{
          inputPurple: style === 'purple',
          isError,
          isDisabled
        }"
        @input="updateValue"
        @focus="isFocus = true"
        @blur="isFocus = false"
      >
        <option v-if="placeholder" value="" disabled selected>
          {{ placeholder }}
        </option>
        <option
          v-for="(option, idx) in options"
          :key="idx"
          :value="option.value"
          :selected="option.value === modelValue"
        >
          {{ option.label }}
        </option>
      </select>
      <div ref="wrapperRef" class="relative hidden md:block">
        <button
          type="button"
          :disabled="isDisabled"
          class="input flex w-full items-center gap-2 text-left"
          :class="{
            'text-neutral-600': modelValue === '',
            inputPurple: style === 'purple',
            isError,
            isDisabled
          }"
          @click="dropdownToggle"
        >
          <span
            v-if="selectedOption?.img"
            class="inline-flex h-[22px] w-[22px] items-center justify-center overflow-hidden rounded-full"
            :style="selectedOption.imgWrapperStyle"
          >
            <img
              :src="selectedOption.img"
              :alt="selectedOption.label"
              :width="22"
              :height="22"
              :style="selectedOption.imgStyle"
            />
          </span>
          {{ modelValue !== '' ? selectedOption?.label : placeholder }}
        </button>
        <div
          v-show="isFocus"
          ref="dropdownRef"
          class="absolute left-0 z-10 w-full rounded-xl bg-white shadow-lg"
          :class="isShown ? 'opacity-1' : 'opacity-0'"
          :style="{
            top: dropdownPosition === 'bottom' ? `calc(100% + ${DROPDOWN_MARGIN_TOP}px)` : 'auto',
            bottom: dropdownPosition === 'top' ? `calc(100% + ${DROPDOWN_MARGIN_TOP}px)` : 'auto'
          }"
        >
          <ul class="flex max-h-[320px] flex-col gap-y-1 overflow-auto p-3">
            <li v-for="(option, idx) in options" :key="idx">
              <button
                type="button"
                class="relative flex w-full items-center gap-2 rounded-xl px-4 py-2.5 pr-6 text-left text-[16px] hover:bg-neutral-50"
                :class="{
                  'bg-neutral-50': option.value === modelValue
                }"
                @click="handleClick(option.value)"
              >
                <span
                  v-if="option?.img"
                  class="inline-flex h-[22px] w-[22px] items-center justify-center overflow-hidden rounded-full"
                  :style="option?.imgWrapperStyle"
                >
                  <img
                    :src="option.img"
                    :alt="option.label"
                    :style="option?.imgStyle"
                    :width="22"
                    :height="22"
                  />
                </span>
                <span class="pr-5px">{{ option.label }}</span>
                <Icon
                  v-show="option.value === modelValue"
                  name="check-general"
                  class="absolute right-5 top-1/2 h-5px w-5px -translate-y-1/2"
                  :color="colors.green"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Icon
        name="chevron-down-general"
        class="absolute right-5 top-1/2 h-3px w-3px -translate-y-1/2 transition"
        :class="{
          'rotate-180': isFocus
        }"
      />
    </div>
  </FormField>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { InputStyles } from './FormInput.vue'
import colors from '#tailwind-config/theme/colors'

const DROPDOWN_MARGIN_TOP = 8

export interface SelectOption {
  img?: string
  imgStyle?: CSSProperties
  imgWrapperStyle?: CSSProperties
  label: string
  value: string
}

interface InputProps {
  classField?: string
  placeholder?: string
  label: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: string
  isDisabled?: boolean
  isError?: boolean
  errorFixed?: boolean
  errorMessage?: string
  style?: InputStyles
  options: Array<SelectOption>
}

const props = withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: '',
  type: 'text',
  isDisabled: false,
  isError: false,
  style: 'gray'
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLSelectElement).value)
}

const selectRef = ref<null | HTMLElement>(null)
const wrapperRef = ref<null | HTMLElement>(null)
const dropdownRef = ref<null | HTMLElement>(null)

const isFocus = ref(false)
const isShown = ref(false)
const dropdownPosition = ref<'top' | 'bottom'>('bottom')

onClickOutside(wrapperRef, () => {
  isFocus.value = false
})

function handleClick(e: any) {
  isFocus.value = false

  emit('update:modelValue', e)
}

function dropdownToggle() {
  isFocus.value = !isFocus.value
}

const selectedOption = computed(
  () => props.options?.find(option => option.value === props.modelValue)
)

const updateDropdownPosition = useDebounceFn(() => {
  setTimeout(() => {
    if (wrapperRef.value && dropdownRef.value) {
      const dropdownHeight = dropdownRef.value.getBoundingClientRect().height
      const wrapperBottom = wrapperRef.value.getBoundingClientRect().bottom
      dropdownPosition.value =
        wrapperBottom + DROPDOWN_MARGIN_TOP + dropdownHeight >= window.innerHeight
          ? 'top'
          : 'bottom'
      isShown.value = isFocus.value
    }
  })
})

watch(() => isFocus.value, updateDropdownPosition)

onMounted(() => {
  updateDropdownPosition()
  window.addEventListener('resize', updateDropdownPosition)
  window.addEventListener('scroll', updateDropdownPosition)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateDropdownPosition)
  window.removeEventListener('scroll', updateDropdownPosition)
})
</script>
